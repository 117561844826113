import { Button, EditIcon, Flex, TrashCanIcon } from '@fluentui/react-northstar';
import { useNavigate } from 'react-router-dom';

export function TableButtons({
    id,
    onDelete,
  }: {
    id: number;
    onDelete: (id: number) => Promise<void>;
  }) {
    const navigate = useNavigate();
    return (
      <Flex gap="gap.small">
        <Button
          icon={<EditIcon />}
          text
          iconOnly
          onClick={() => navigate(`./${id}`)}
        />
        <Button
          icon={<TrashCanIcon />}
          onClick={() => onDelete(id)}
          text
          iconOnly
        />
      </Flex>
    );
  }