import { useEffect, useMemo, useState } from 'react';

export function useForm<T extends Object>(defaultValue: Partial<T>) {
  const [formData, setFormData] = useState<Partial<T>>({});
  useEffect(() => {
    setFormData(defaultValue);
  }, [defaultValue]);

  const setFieldValue = useMemo(
    () => (name: string, value: any) =>
      setFormData({ ...formData, [name]: value }),
    [formData, setFormData]
  );

  return { formData, setFormData, setFieldValue };
}
