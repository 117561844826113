import { Flex, Form, FormButton, FormInput } from '@fluentui/react-northstar';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader, useApiClient, useApiData, useForm } from '../../common';
import { endpointPath, SiteDto } from './models';
import { SiteFormTeams } from './SiteFormTeams';

function SiteForm() {
    const navigate = useNavigate();
    const routeParams = useParams();
    const [department] = useApiData<SiteDto>(
        `${endpointPath}/${routeParams.id}`,
        routeParams.id !== 'new',
        {} as SiteDto
    );

    const breadcrumb = useMemo(
        () => [
            {
                name: 'Sites',
                href: '/sites',
            },
            {
                name: department?.nameEn || 'New site',
            },
        ],
        [department]
    );
    const api = useApiClient();

    const { formData, setFieldValue } = useForm<SiteDto>(department!);

    return (
        <>
            <PageHeader
                title={routeParams.id !== 'new' ? 'Edit' : 'New site'}
                breadcrumb={breadcrumb}
            />
            <Form
                onSubmit={(e) => {
                    (async () => {
                        formData.teams = formData.teams?.map((x) => ({
                            ...x,
                            id: x.id! > 0 ? x.id : undefined,
                        })) || [];
                        await api.post(endpointPath, formData, 'The site was saved');
                        navigate('..');
                    })();
                }}
                onReset={() => {
                    navigate('..');
                }}
            >
                <FormInput
                    label="Name EN"
                    name="nameEn"
                    onChange={(e, d) => setFieldValue('nameEn', d?.value)}
                    value={formData.nameEn}
                    required
                />
                <FormInput
                    label="Name PL"
                    name="namePl"
                    onChange={(e, d) => setFieldValue('namePl', d?.value)}
                    value={formData.namePl}
                />
                <FormInput
                    label="Abbreviations"
                    name="abbreviations"
                    onChange={(e, d) => setFieldValue('abbreviations', d?.value)}
                    value={formData.abbreviations}
                    required
                />
                <SiteFormTeams
                    teams={formData.teams || []}
                    onChange={(teams) => setFieldValue('teams', teams)}
                />
                <Flex gap="gap.small">
                    <FormButton content="Save" type="submit" primary />
                    <FormButton content="Cancel" type="reset" />
                </Flex>
            </Form>
        </>
    );
}

export default SiteForm;
