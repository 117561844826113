import { AddIcon } from '@fluentui/react-icons-northstar';
import { Button, Skeleton, Table } from '@fluentui/react-northstar';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  PageHeader,
  SettingsBreadcrumbItem,
  TableButtons,
  useApiClient,
  useApiData
} from '../';

export interface ListItem {
  id?: number;
  nameEn: string;
  namePl?: string;
}

export interface ItemsListPageProps {
  title: string;
  pageUrl: string;
  endpointPath: string;
}

export function ItemsListPage({
  title,
  pageUrl,
  endpointPath,
}: ItemsListPageProps) {
  const routeParams = useParams();
  const api = useApiClient();
  const [departments, isLoading, refreshDepartments] = useApiData<ListItem[]>(
    endpointPath,
    !routeParams?.id
  );

  const header = {
    items: ['id', 'Name EN', 'Name PL', 'Actions'],
  };

  const breadcrumb: SettingsBreadcrumbItem[] = useMemo(
    () => [
      {
        name: title,
        href: pageUrl,
      },
    ],
    [title, pageUrl]
  );

  const deleteItem = useMemo(
    () => async (id: number) => {
      if (window.confirm('Are you sure you want to delete this item?')) {
        await api.delete(`${endpointPath}/${id}`, 'The department was deleted');
        refreshDepartments();
      }
    },
    [api]
  );

  const rows = useMemo(
    () =>
      departments?.map((x) => ({
        key: x.id,
        items: [
          x.id,
          <span key={`${x.id}-en-${x.nameEn}`}>{x.nameEn}</span>,
          <span key={`${x.id}-pl-${x.namePl}`}>{x.namePl}</span>,
          <TableButtons
            key={`btns-${x.id}`}
            id={x.id!}
            onDelete={deleteItem}
          />,
        ],
      })),
    [departments]
  );

  return (
    <>
      <PageHeader title={title} breadcrumb={breadcrumb} />
      <div>
        <Link to="new">
          <Button icon={<AddIcon />} content="New" tinted />
        </Link>
      </div>
      {isLoading ? (
        <Skeleton
          animation="wave"
          content={
            <Table header={header} rows={[]} aria-label="Static table" />
          }
        />
      ) : (
        <Table header={header} rows={rows} aria-label="Static table" />
      )}
    </>
  );
}
