import {
  BuildingIcon,
  PersonIcon,
  WorkOrSchoolIcon
} from '@fluentui/react-icons-northstar';
import { Flex, Menu, Segment } from '@fluentui/react-northstar';

import { app } from '@microsoft/teams-js';
import { useEffect, useMemo, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import './App.css';
import { Alerts, AlertsContext, useCreateAlertsContext } from './common';
import {
  useCreateUserSettingsContext,
  UserSettingsContext
} from './modules/user';

function App() {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [welcomeMessage, setWelcomeMessage] = useState<string>('');

  const alertsContext = useCreateAlertsContext();
  const userSettingsContext = useCreateUserSettingsContext(alertsContext);

  useEffect(() => {
    (async () => {
      try {
        await app.initialize();
        await userSettingsContext.fetch();

        setInitialized(true);
      } catch (error) {
        setWelcomeMessage(
          'Please chat with the bot first before using the settings page. Just write “hi” or “help” in the chat window to activate the bot and get instructions.'
        );
      }
    })();
  }, []);

  const menuItems = useMemo(
    () =>
      [
        {
          key: 'personal',
          icon: <PersonIcon />,
          content: <Link to="/personal">User settings</Link>,
        },
        {
          key: 'departments',
          icon: <WorkOrSchoolIcon />,
          content: <Link to="/departments">Departments</Link>,
        },
        {
          key: 'sites',
          icon: <BuildingIcon />,
          content: <Link to="/sites">Sites</Link>,
        },
      ].filter(
        (x) => userSettingsContext.user?.isAdmin || ['personal'].includes(x.key)
      ),
    [userSettingsContext.user]
  );

  return (
    <AlertsContext.Provider value={alertsContext}>
      <UserSettingsContext.Provider value={userSettingsContext}>
        <div className="App">
          <Alerts />
          {initialized && (
            <Segment
              content={
                <Flex gap="gap.large">
                  <Menu items={menuItems} vertical />
                  <Flex.Item grow>
                    <Flex gap="gap.medium" column>
                      <Outlet />
                    </Flex>
                  </Flex.Item>
                </Flex>
              }
            />
          )}
          {welcomeMessage && <h1>{welcomeMessage}</h1>}
        </div>
      </UserSettingsContext.Provider>
    </AlertsContext.Provider>
  );
}

export default App;
