import { createComponent, FormLabel } from '@fluentui/react-northstar';
import { PeoplePicker, PersonType } from '@microsoft/mgt-react';

// TODO: Use Fluent UI theming
import './GroupPicker.css';

export interface GroupPickerProps {
  label?: string;
  value?: string;
  required?: boolean;
  onChange: (newValue?: string) => void;
}

export const GroupPicker = createComponent<GroupPickerProps>({
  displayName: 'GroupPicker',
  render: ({ config, label, onChange, value, required }) => (
    <div className={config.classes.root} style={config.styles}>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <div style={{ maxWidth: 365 }}>
        <PeoplePicker
          type={PersonType.group}
          selectionMode="single"
          selectionChanged={(e) => onChange((e as any).detail[0]?.id)}
          defaultSelectedGroupIds={value ? [value] : undefined}
          disableImages
        />
      </div>
    </div>
  ),
});
