import { Form, FormButton, FormDropdown } from '@fluentui/react-northstar';
import { useMemo } from 'react';
import {
  PageHeader,
  SettingsBreadcrumbItem,
  useApiData,
  useForm
} from '../../common';
import { SiteDto } from '../sites';
import { useUserSettings } from './userContext';

const breadcrumb: SettingsBreadcrumbItem[] = [
  {
    name: 'Personal',
    href: '/personal',
  },
];

function PersonalSettings() {
  const [sites, isLoading] = useApiData<SiteDto[]>('/sites', true, []);
  const { user, fetch, update } = useUserSettings();
  const { formData, setFieldValue } = useForm(user);

  const dropdownItems = useMemo(() => sites!.map((x) => x.nameEn), [sites]);
  return (
    <>
      <PageHeader title="User settings" breadcrumb={breadcrumb} />
      <Form
        onSubmit={() => {
          (async () =>
            await update({
              selectedSiteId: formData.selectedSiteId!,
              isAdmin: false,
            }))();
        }}
      >
        <FormDropdown
          label="Your location"
          items={dropdownItems}
          id="site"
          placeholder="Choose a location"
          onChange={(e, d) =>
            setFieldValue(
              'selectedSiteId',
              sites?.find((x) => x.nameEn === d.value)?.id
            )
          }
          value={sites?.find((x) => x.id === formData.selectedSiteId)?.nameEn}
        />
        <FormButton content="Save" primary />
      </Form>
    </>
  );
}

export default PersonalSettings;
