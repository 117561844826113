import { ItemsListPage } from '../../common';
import { endpointPath } from './models';

export default function DepartmentsList() {
  return (
    <ItemsListPage
      endpointPath={endpointPath}
      title="Departments"
      pageUrl="/departments"
    />
  );
}
