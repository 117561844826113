import {
    Flex,
    FormButton,
    FormDropdown,
    FormLabel
} from '@fluentui/react-northstar';
import { useMemo, useState } from 'react';
import { useApiData } from '../../common';
import {
    DepartmentDto,
    endpointPath as departmentsEndpoint
} from '../departments/models';
import { SiteFormTeamsProps, TeamDto } from './models';

export function FormAddMisingRowsAction({
  teams,
  onChange,
}: SiteFormTeamsProps) {
  const usedDepartments = useMemo(
    () => teams.map((x) => x.departmentId).filter(Boolean),
    [teams]
  );
  const [departments] = useApiData<DepartmentDto[]>(
    departmentsEndpoint,
    true,
    []
  );

  const [selectedDepartments, setSelectedDepartments] = useState<
    DepartmentDto[]
  >([]);

  const selectedNames = useMemo(
    () => selectedDepartments.map((x) => x.nameEn),
    [selectedDepartments]
  );

  const availableDepartments = useMemo(
    () => departments!.filter((x) => usedDepartments.indexOf(x.id!) === -1),
    [departments, usedDepartments]
  );

  const dropdownItems = useMemo(
    () => availableDepartments.map((x) => x.nameEn),
    [availableDepartments]
  );

  const onRowsAdd = useMemo(
    () => (departments: DepartmentDto[]) => {
      const teamsCopy = [...teams];

      departments.reduce(
        (l, item) =>
          teamsCopy.push({
            id: -l,
            departmentId: item.id,
            departmentNameEn: item.nameEn,
          } as TeamDto),
        teamsCopy.length
      );

      onChange(teamsCopy);
    },
    [teams]
  );

  const onAddClicked = useMemo(
    () => () => {
      onRowsAdd(selectedDepartments);
      setSelectedDepartments([]);
    },
    [selectedDepartments, onRowsAdd]
  );

  return availableDepartments.length ? (
    <Flex gap="gap.small">
      <FormDropdown
        label="Add departments"
        items={dropdownItems}
        id="missing-teams"
        multiple={true}
        onChange={(e, d) => {
          const selected = (d.value as string[]).map(
            (s) => availableDepartments!.find((x) => x.nameEn === s)!
          );
          setSelectedDepartments(selected);
        }}
        value={selectedNames}
      />
      <div>
        <FormLabel>&nbsp;</FormLabel>
        <FormButton content="Add" type="button" onClick={onAddClicked} />
      </div>
    </Flex>
  ) : (
    <></>
  );
}
