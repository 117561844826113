import { useEffect, useState } from 'react';
import { useApiClient } from '../services/ApiClient';

export function useApiData<T>(
  path: string,
  condition: boolean,
  defaultValue?: T,
  deps?: any[]
): [T | undefined, boolean, () => void] {
  const client = useApiClient();

  const [refreshCount, setRefreshCount] = useState(0);
  const [data, setData] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (condition) {
        setIsLoading(true);
        const apiData = await client.get<T>(path);
        setData(apiData);
        setIsLoading(false);
      }
    })();
  }, [path, refreshCount, condition, ...(deps || [])]);

  return [data, isLoading, () => setRefreshCount(refreshCount + 1)];
}
