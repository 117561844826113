import {
  Flex,
  Form,
  FormButton,
  FormCheckbox,
  FormInput
} from '@fluentui/react-northstar';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GroupPicker,
  PageHeader,
  useApiClient,
  useApiData,
  useForm
} from '../../common';
import { DepartmentDto, endpointPath } from './models';

function DepartmentForm() {
  const navigate = useNavigate();
  const routeParams = useParams();
  const [department] = useApiData<DepartmentDto>(
    `${endpointPath}/${routeParams.id}`,
    routeParams.id !== 'new',
    {} as DepartmentDto
  );

  const breadcrumb = useMemo(
    () => [
      {
        name: 'Departments',
        href: '/departments',
      },
      {
        name: department?.nameEn || 'New department',
      },
    ],
    [department]
  );
  const api = useApiClient();

  const { formData, setFieldValue } = useForm<DepartmentDto>(department!);

  return (
    <>
      <PageHeader
        title={routeParams.id !== 'new' ? 'Edit' : 'New department'}
        breadcrumb={breadcrumb}
      />
      <Form
        onSubmit={(e) => {
          (async () => {
            await api.post(endpointPath, formData, 'The department was saved');
            navigate('..');
          })();
        }}
        onReset={() => {
          navigate('..');
        }}
      >
        <FormInput
          label="Name EN"
          name="nameEn"
          onChange={(e, d) => setFieldValue('nameEn', d?.value)}
          value={formData.nameEn}
          required
        />
        <FormInput
          label="Name PL"
          name="namePl"
          onChange={(e, d) => setFieldValue('namePl', d?.value)}
          value={formData.namePl}
        />
        <FormCheckbox
          label="Is global department"
          checked={formData.isGlobal}
          onChange={(e, d) => setFieldValue('isGlobal', d?.checked)}
        />
        {formData.isGlobal && (
          <GroupPicker
            label="Team group"
            onChange={(val) => setFieldValue('departmentAdGroupId', val)}
            value={formData.departmentAdGroupId}
            required={formData.isGlobal}
          />
        )}
        <Flex gap="gap.small">
          <FormButton content="Save" type="submit" primary />
          <FormButton content="Cancel" type="reset" />
        </Flex>
      </Form>
    </>
  );
}

export default DepartmentForm;
