import { authentication } from '@microsoft/teams-js';
import { useMemo } from 'react';
import { IAlertsContext, useAlertsContext } from '../contexts/alertsContext';

type HttpMethods = 'GET' | 'POST' | 'DELETE';

export function useApiClient() {
  const alertsContext = useAlertsContext();
  return useMemo(() => new ApiClient(alertsContext), [alertsContext]);
}

export class ApiClient {
  private readonly baseUrl = '/api';

  constructor(private readonly alertsContext: IAlertsContext) {}

  public get<T>(path: string): Promise<T> {
    return this.fetch(path, 'GET');
  }

  public async post<T>(
    path: string,
    data: any,
    successMessage?: string
  ): Promise<T> {
    const res = await this.fetch<T>(path, 'POST', data);
    if (successMessage) {
      this.alertsContext.addAlert({ content: successMessage, success: true });
    }
    return res;
  }

  public async delete<T>(path: string, successMessage?: string): Promise<T> {
    const res = await this.fetch<T>(path, 'DELETE');
    if (successMessage) {
      this.alertsContext.addAlert({ content: successMessage, success: true });
    }
    return res;
  }

  private async fetch<T>(
    path: string,
    method: HttpMethods,
    data: any = undefined
  ): Promise<T> {
    try {
      const token = await authentication.getAuthToken();
      const response = await fetch(this.baseUrl + path, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: data ? JSON.stringify(data) : null,
      } as any);

      return (await response.json()) as T;
    } catch (error) {
      this.alertsContext.addAlert({
        content: (error as any)?.message,
        danger: true,
      });
      throw new Error((error as any)?.message);
    }
  }
}
