import { createContext, useContext, useMemo, useState } from 'react';
import { IAlertsContext, useAlertsContext, useApiClient } from '../../common';
import { endpointPath, UserDto } from './models';

export interface IUserSettingsContext {
  user: UserDto;
  fetch: () => Promise<void>;
  update: (data: UserDto) => Promise<void>;
}

const defaultUserValue = { isAdmin: false, selectedSiteId: 0 };

export const UserSettingsContext = createContext<IUserSettingsContext>({
  user: defaultUserValue,
  fetch: () => new Promise((resolve) => resolve()),
  update: (data: UserDto) => new Promise((resolve) => resolve()),
});

export function useCreateUserSettingsContext({
  addAlert,
}: IAlertsContext): IUserSettingsContext {
  const [user, setUser] = useState<UserDto>(defaultUserValue);
  const apiClient = useApiClient();

  const fetch = useMemo(() => {
    return async () => {
      const data = await apiClient.get<UserDto>(`${endpointPath}/me`);
      setUser(data);
    };
  }, [setUser, apiClient]);

  const update = useMemo(() => {
    return async (data: UserDto) => {
      await apiClient.post<UserDto>(`${endpointPath}/me`, {
        selectedSiteId: data.selectedSiteId,
      } as UserDto);

      addAlert({ content: 'Saved user settings', success: true });
      await fetch();
    };
  }, [setUser, apiClient, addAlert]);

  return {
    user,
    fetch,
    update,
  };
}

export function useUserSettings() {
  return useContext(UserSettingsContext);
}
