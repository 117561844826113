import { Button, Flex, TrashCanIcon } from '@fluentui/react-northstar';

export function TeamsTableButtons({
    id, onDelete,
}: {
    id: number;
    onDelete: (id: number) => void;
}) {
    return (
        <Flex gap="gap.small">
            <Button
                icon={<TrashCanIcon />}
                onClick={() => onDelete(id)}
                text
                iconOnly />
        </Flex>
    );
}
