import { AlertProps } from '@fluentui/react-northstar';
import { createContext, useContext, useMemo, useState } from 'react';

export interface ContextAlert extends AlertProps {
  key: number;
}

export interface IAlertsContext {
  alerts: ContextAlert[];
  addAlert: (props: AlertProps) => void;
  dismissAlert: (key: number) => void;
}

export const AlertsContext = createContext<IAlertsContext>({
  alerts: [],
  addAlert: (props: AlertProps) => {},
  dismissAlert: (key: number) => {},
});

export function useCreateAlertsContext(): IAlertsContext {
  const [alerts, setAlerts] = useState<ContextAlert[]>([]);

  const dismiss = useMemo(
    () => (key: number) => {
      const ix = alerts.findIndex((x) => x.key === key);
      if (ix !== -1) {
        alerts.splice(ix, 1);
        setAlerts([...alerts]);
      }
    },
    [alerts]
  );

  const add = useMemo(
    () => (props: AlertProps) => {
      const newAlert = { ...props, key: new Date().getTime() };
      setAlerts([...alerts, newAlert]);
    },
    [alerts, dismiss]
  );

  return {
    alerts,
    addAlert: add,
    dismissAlert: dismiss,
  };
}

export function useAlertsContext() {
  return useContext(AlertsContext);
}
