import { Divider, Header } from '@fluentui/react-northstar';
import {
    SettingsBreadcrumb,
    SettingsBreadcrumbItem
} from './SettingsBreadcrumb';

export interface PageHeaderProps {
  title: string;
  breadcrumb: SettingsBreadcrumbItem[];
}

export function PageHeader({ title, breadcrumb }: PageHeaderProps) {
  return (
    <>
      <SettingsBreadcrumb items={breadcrumb} />
      <Header as="h1" content={title} styles={{ marginTop: 0 }} />
      <Divider />
    </>
  );
}
