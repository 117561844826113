import { Alert } from '@fluentui/react-northstar';
import { useEffect, useMemo } from 'react';
import { ContextAlert, useAlertsContext } from '../contexts/alertsContext';

export function Alerts() {
  const { alerts, dismissAlert: dismiss } = useAlertsContext();

  return (
    <>
      {alerts.map((props: ContextAlert) => (
        <AutoDismissAlert alert={props} dismiss={dismiss} timeout={3000} />
      ))}
    </>
  );
}
interface AutoDismissAlertProps {
  timeout: number;
  dismiss: (key: number) => void;
  alert: ContextAlert;
}
function AutoDismissAlert({ dismiss, alert, timeout }: AutoDismissAlertProps) {
  const onVisibleChange = useMemo(
    () =>
      ({ key }: ContextAlert) =>
        dismiss(key),
    [dismiss]
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!alert.danger) {
        dismiss(alert.key);
      }
    }, timeout);

    return () => clearTimeout(timer);
  }, [dismiss, alert]);
  return (
    <Alert
      {...alert}
      onVisibleChange={() => onVisibleChange(alert)}
      dismissible
    />
  );
}
