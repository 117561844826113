export const endpointPath = '/sites';
export interface SiteDto {
  id?: number;
  nameEn: string;
  namePl?: string;
  abbreviations: string;
  teams: TeamDto[];
}

export interface TeamDto {
  id?: number;
  departmentId: number;
  departmentNameEn: string;
  adGroupId: string;
}

export interface SiteFormTeamsProps {
  teams: TeamDto[];
  onChange: (teams: TeamDto[]) => void;
}
