import { Provider, teamsTheme } from '@fluentui/react-northstar';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import './index.css';
import DepartmentForm from './modules/departments/DepartmentForm';
import DepartmentsList from './modules/departments/DepartmentsList';
import SiteForm from './modules/sites/SiteForm';
import SitesList from './modules/sites/SitesList';
import PersonalSettings from './modules/user/PersonalSettings';
import reportWebVitals from './reportWebVitals';

import { Providers } from '@microsoft/mgt-element';
import {
  HttpMethod,
  TeamsMsal2Provider
} from '@microsoft/mgt-teams-msal2-provider';
import * as Teams from '@microsoft/teams-js';
import Auth from './Auth';

TeamsMsal2Provider.microsoftTeamsLib = Teams;

Providers.globalProvider = new TeamsMsal2Provider({
  clientId: 'msteams-tab',
  authPopupUrl: '/auth',
  ssoUrl: '/api/tokens/graph',
  httpMethod: HttpMethod.POST,
  scopes: ['Group.Read.All'],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider theme={teamsTheme}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route element={<PersonalSettings />} index />
            <Route path="personal" element={<PersonalSettings />} />
            <Route path="departments">
              <Route element={<DepartmentsList />} index />
              <Route path=":id" element={<DepartmentForm />} />
            </Route>
            <Route path="sites">
              <Route element={<SitesList />} index />
              <Route path=":id" element={<SiteForm />} />
            </Route>
            <Route path="auth" element={<Auth />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
