import { Table } from '@fluentui/react-northstar';
import { useMemo } from 'react';
import { GroupPicker } from '../../common';
import { FormAddMisingRowsAction } from './FormAddMisingRowsAction';
import { SiteFormTeamsProps } from './models';
import { TeamsTableButtons } from './TeamsTableButtons';

export function SiteFormTeams({ teams, onChange }: SiteFormTeamsProps) {
  const header = useMemo(
    () => ({
      items: ['id', 'Department', 'Group', ''],
    }),
    []
  );

  const onRowDelete = useMemo(
    () => (id: number) => {
      const teamsCopy = [...teams];
      const ix = teamsCopy.findIndex((x) => x.id === id);
      if (ix !== -1) {
        teamsCopy.splice(ix, 1);
        onChange(teamsCopy);
      }
    },
    [teams]
  );

  const rows = useMemo(
    () =>
      teams.map((team, i) => ({
        key: team.id,
        items: [
          team.id! > 0 ? team.id : 'new',
          team.departmentNameEn,
          <GroupPicker
            key={'gp-' + team.id}
            onChange={(val) => (team.adGroupId = val || '')}
            value={team.adGroupId}
          />,
          <TeamsTableButtons
            key={'tb-' + team.id!}
            id={team.id!}
            onDelete={onRowDelete}
          />,
        ],
      })),
    [teams]
  );

  return (
    <>
      <FormAddMisingRowsAction teams={teams} onChange={onChange} />
      <Table header={header} rows={rows} />
    </>
  );
}
