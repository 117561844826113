import { Breadcrumb } from '@fluentui/react-northstar';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export interface SettingsBreadcrumbItem {
  name: string;
  href?: string;
}

export function SettingsBreadcrumb({
  items,
}: {
  items: SettingsBreadcrumbItem[];
}) {
  const fullItems = useMemo(
    () =>
      ([{ name: 'Settings', href: '/' }] as SettingsBreadcrumbItem[]).concat(
        items
      ),
    [items]
  );

  return (
    <Breadcrumb aria-label="breadcrumb">
      {fullItems.map((item, i) => (
        <>
          {i !== 0 && <Breadcrumb.Divider />}
          <Breadcrumb.Item
            key={item.name}
            styles={{ paddingLeft: i === 0 ? '0' : undefined }}
          >
            {item.href ? <Link to={item.href}>{item.name}</Link> : item.name}
          </Breadcrumb.Item>
        </>
      ))}
    </Breadcrumb>
  );
}
